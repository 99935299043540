import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatisticAPI } from "../../services/statistics.service";
import { IOptions } from "../../types/NetworkState";
import { loading } from "../app/app.reducer";

export type StatisticDataType = {
  avg_salary: number;
  res_id: number;
  res_name: string;
  res_code?: string;
  result_demand: number;
  result_offer: number;
  result_remote_demand: number;
  result_remote_offer: number;
  result_ratio: number;
};

export type StatisticDataBox = {
  x: string;
  low: number;
  q1: number;
  median: number;
  q3: number;
  high: number;
};

export interface IStatistics {
  skills: StatisticDataType[];
  positions: StatisticDataType[];
  fields: StatisticDataType[];
  country: StatisticDataType[];
  region: StatisticDataType[];
}

const initialState: IStatistics = {
  skills: [],
  positions: [],
  country: [],
  region: [],
  fields: [],
};

export const fetchSkillsStatistics = createAsyncThunk(
  "statistics/fetchSkillsStatistics",
  async (options: IOptions) => {
    const response = await StatisticAPI.fetchByTop("skills", options);
    return response;
  }
);

export const fetchPositionsStatistics = createAsyncThunk(
  "statistics/fetchPositionsStatistics",
  async (options: IOptions) => {
    const response = await StatisticAPI.fetchByTop("specials", options);
    return response;
  }
);

export const fetchFieldsStatistics = createAsyncThunk(
  "statistics/fetchFieldsStatistics",
  async (options: IOptions) => {
    const response = await StatisticAPI.fetchByTop("subjectareas", options);
    return response;
  }
);

export const fetchCountryStatistics = createAsyncThunk(
  "statistics/fetchCountryStatistics",
  async (options: IOptions, thunkApi) => {
    thunkApi.dispatch(loading(true));
    const country = await StatisticAPI.fetchByCountry(options);
    thunkApi.dispatch(loading(false));
    return country;
  }
);

export const fetchCitiesStatistics = createAsyncThunk(
  "statistics/fetchCitiesStatistics",
  async (options: IOptions, thunkApi) => {
    thunkApi.dispatch(loading(true));
    const country = await StatisticAPI.fetchByRegion(options);
    thunkApi.dispatch(loading(false));
    return country;
  }
);

const statisticsSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setStatistics(
      state,
      action: PayloadAction<{ key: keyof IStatistics; value: StatisticDataType[] }>
    ) {
      return { ...state, [action.payload.key]: action.payload.value };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSkillsStatistics.fulfilled, (state, action) => {
        return { ...state, skills: action.payload };
      })
      .addCase(fetchPositionsStatistics.fulfilled, (state, action) => {
        return { ...state, positions: action.payload };
      })
      .addCase(fetchFieldsStatistics.fulfilled, (state, action) => {
        return { ...state, fields: action.payload };
      })
      .addCase(fetchCountryStatistics.fulfilled, (state, action) => {
        return { ...state, country: action.payload };
      })
      .addCase(fetchCitiesStatistics.fulfilled, (state, action) => {
        return { ...state, region: action.payload };
      });
  },
});

const { actions, reducer } = statisticsSlice;

export const { setStatistics } = actions;

export default reducer;
