import React from "react";
import { DashboardWrapper } from "./Dashboard.styled";
import DynamicVacancies from "../Charts/DynamicVacancies";
import DynamicSalaries from "../Charts/DynamicSalaries";
import SelectMapRegion from "./SelectMapRegion";

const Dashboard: React.FC = () => {
  return (
    <DashboardWrapper>
      <SelectMapRegion />
      <DynamicVacancies />
      <DynamicSalaries />
    </DashboardWrapper>
  );
};

export default Dashboard;
