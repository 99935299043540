import React, { useCallback, useEffect, useState } from "react";
import { Box, BoxConfig } from "@ant-design/charts";
import { ChartSubtitle, ChartTitle, ChartWrapper } from "./Charts.styled";
import { StatisticAPI } from "../../services/statistics.service";
import { useFilters } from "../../hooks/filters.hooks";
import { StatisticDataBox } from "../../store/statistics/statistics.reducer";
import { useRegion } from "hooks/region.hooks";

function DynamicSalaries() {
  const [filter] = useFilters();
  const region = useRegion();
  const [data, setData] = useState<StatisticDataBox[]>([]);

  const asyncFetch = useCallback(async () => {
    try {
      const response = await StatisticAPI.fetchSalaries({
        filters: filter,
        region_id: region?.res_id,
      });
      setData(response);
    } catch (error) {}
  }, [filter, region?.res_id]);

  useEffect(() => {
    asyncFetch();
  }, [asyncFetch]);

  const config: BoxConfig = {
    data,
    height: 300,
    xField: "x",
    yField: ["low", "q1", "median", "q3", "high"],
  };

  return (
    <ChartWrapper>
      <ChartTitle>Динамика зарплат</ChartTitle>
      <ChartSubtitle>Зарплата, указанная в вакансиях, руб.</ChartSubtitle>
      <Box {...config} />
    </ChartWrapper>
  );
}

export default DynamicSalaries;
