import { useCallback, useMemo } from "react";

import { useSearchParams } from "react-router-dom";

export type FilterParams = { [key: string]: string | number };

// Оптимизировать в дальнейшем
export const useFilters = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const state = useMemo(() => {
    const filter: FilterParams = {};
    searchParams.forEach((value, key) => {
      //console.log(`search`, key, value);
      filter[key] = !isNaN(+value) ? +value : value;
    });
    return filter;
  }, [searchParams]);

  const setState = useCallback(
    (key: string, value?: string | number) => {
      const search = new URLSearchParams(searchParams);

      if (value) {
        if (search.has(key)) {
          search.set(key, value.toString());
        } else {
          search.append(key, value.toString());
        }

        console.log(`add`, search.toString());
        setSearchParams(search, { replace: true });
      } else {
        search.delete(key);
        console.log(`delete`, search.toString());
        setSearchParams(search, { replace: true });
      }
    },
    [searchParams, setSearchParams]
  );

  const clear = useCallback(
    (key: string) => {
      let search = new URLSearchParams(searchParams);
      search.delete(key);
      setSearchParams(search);
    },
    [searchParams, setSearchParams]
  );

  return [state, setState, clear] as const;
};
